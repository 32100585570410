<template>
  <div class="bigBox">
    <div class="tab-colleges">
      <div class="tab-item" :class="[cardStates == 'all' ? 'active' : '']">
        <div class="tab-text" @click="navChange('all')">全部</div>
        <div class="tab-line" />
      </div>
      <div class="tab-item" :class="[cardStates == 0 ? 'active' : '']">
        <div class="tab-text" @click="navChange(0)">已激活</div>
        <div class="tab-line" />
      </div>
      <div class="tab-item" :class="[cardStates == 1 ? 'active' : '']">
        <div class="tab-text" @click="navChange(1)">已过期</div>
        <div class="tab-line" />
      </div>
      <div class="tab-item" :class="[cardStates == 2 ? 'active' : '']">
        <div class="tab-text" @click="navChange(2)">学习卡激活</div>
        <div class="tab-line" />
      </div>
    </div>
    <!-- 学习卡列表 -->
    <div v-show="cardStates == 'all' || cardStates == 0 || cardStates == 1" class="contentBox">
      <el-empty v-if="list.length == 0" description="暂无数据" />
      <div v-for="(item, index) in list" :key="index" class="cardItem" @click="goDetail(item)">
        <div class="itemBox">
          <img class="itemImg" :src="item.img">
          <div v-if="item.label == 'nomal'" class="label">已激活</div>
          <div v-if="item.label == 'dead'" class="labelExpired">已过期</div>
          <div class="contentItem">
            <div class="contentTitle">
              {{ item.title ? item.title : item.tiitle }}
            </div>
            <div class="tips">
              {{ liveEnum[item.types] ? liveEnum[item.types] : "知识套餐" }}
            </div>
            <div class="time">到期时间：{{ item.deadTimes }}</div>
          </div>
        </div>
        <!-- <div class="colleges-learn" @click="goDetail(item)">立即学习</div> -->
      </div>
    </div>
    <!-- 学习卡激活 -->
    <div v-show="cardStates == 2" class="activation">
      <div class="activationBox">
        <el-form class="colleges-form">
          <el-form-item label="卡号">
            <el-input v-model="cardCode" class="inputItem" placeholder="请输入卡号" />
          </el-form-item>
          <el-form-item label="密码">
            <el-input v-model="cardPass" class="inputItem" placeholder="请输入密码" />
          </el-form-item>
        </el-form>
        <div v-show="errTipsInfo" class="errorTips">{{ errorTips }}</div>
        <el-button :disabled="btnDisabled" class="activationBtn" @click="submit">确认激活</el-button>
      </div>
    </div>
    <el-dialog class="error-dialog" :visible.sync="dialogVisible" :before-close="handleClose">
      <div class="dialogBox">
        <!-- <img class="dialogImg" src="@/assets/img/Home/Group 465.png" /> -->
        <div class="dialogTips">激活成功</div>
        <div class="className">
          {{ activeStudyCardtInfo ? activeStudyCardtInfo.productName : "" }}
        </div>
        <div class="time">
          到期时间：{{
            activeStudyCardtInfo ? format(new Date(activeStudyCardtInfo.deadTime), "yyyy-MM-dd") : ""
          }}
        </div>
        <div class="colleges-learn" @click="handleClose">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getActStudyProductList, activeStudyCardt } from '@/api/home.js'
import { liveEnum } from '@/api/emun.js'
import Vue from 'vue'
import { getInfo } from '@/api/cookies'
import format from 'date-fns/format'
export default {
  data() {
    return {
      cardStates: 'all',
      cardCode: '', // 卡号
      cardPass: '', // 卡密
      dialogVisible: false,
      activeStudyCardtInfo: null, // 激活返回状态  是否成功
      errTipsInfo: false,
      nomalProdList: [], // 已激活列表
      deadProdList: [], // 已过期列表
      list: [], // 渲染的列表
      liveEnum: null,
      errorTips: null,
      btnDisabled: false
    }
  },
  created() {
    this.liveEnum = liveEnum
  },
  mounted() {
    this.getActStudyProductList()
  },
  methods: {
    format,
    navChange(val) {
      this.list = []
      const arr = []
      if (val == 'all') {
        if (this.nomalProdList.length > 0) {
          arr.push(...this.nomalProdList)
        }
        if (this.deadProdList.length > 0) {
          arr.push(...this.deadProdList)
        }
      } else if (val == 0) {
        if (this.nomalProdList.length > 0) {
          arr.push(...this.nomalProdList)
        } else {
          this.list = []
        }
      } else if (val == 1) {
        if (this.deadProdList.length > 0) {
          arr.push(...this.deadProdList)
        } else {
          this.list = []
        }
      }
      this.list = arr

      this.cardStates = val
      this.pageSize = 8
      this.pageNum = 1
    },
    /* 学习卡下套餐列表 */
    getActStudyProductList() {
      const data = {
        userId: getInfo().id
      }
      getActStudyProductList(data).then((res) => {
        if (res.code == 0 && res.msg) {
          this.nomalProdList = res.msg.nomalProdList
          this.deadProdList = res.msg.deadProdList
          this.list.push(...res.msg.nomalProdList, ...res.msg.deadProdList)
        }
      })
    },
    /* 确认激活 */
    submit() {
      this.btnDisabled = true
      const data = {
        cardCode: this.cardCode,
        cardPass: this.cardPass
      }
      activeStudyCardt(data).then((res) => {
        if (res.code == 0) {
          this.errTipsInfo = false
          this.activeStudyCardtInfo = res.msg
          this.dialogVisible = true
          this.getActStudyProductList()
          this.btnDisabled = false
        } else {
          this.errorTips = res.msg
          this.errTipsInfo = true
          this.btnDisabled = false
        }
      })
    },
    goDetail(item) {
      let type = null
      if (item.types == 1) {
        type = 2
      } else if (item.types == 2) {
        type = 3
      } else if (item.types == 3) {
        type = 7
      } else if (item.types == 4) {
        type = 8
      } else if (item.types == 5) {
        type = 10
      }
      if (item.type != 0) {
        Vue.prototype.goTypeDetail(
          type,
          item.id,
          undefined,
          undefined,
          undefined,
          undefined,
          item.cardCode
        )
      } else {
        if (item.productLine == '3') {
          this.$router.push({
            path: `/VIP/VIPClassxq?id=${item.id}&cardCode=${item.cardCode}`
          })
        } else {
          this.$router.push({
            path: `/typeclassxq?id=${item.id}&cardCode=${item.cardCode}`
          })
        }
      }
    },
    /* 关闭弹窗回调 */
    handleClose() {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.bigBox {
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  // padding: 0px 24px 20px;

  .contentBox {
    width: 100%;
    margin-top: 24px;

    .cardItem {
      width: 995px;
      height: 134px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.06);
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      margin-bottom: 20px;

      .itemBox {
        display: flex;
        position: relative;

        .itemImg {
          width: 205px;
          height: 114px;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          margin-right: 20px;
          flex-shrink: 0;
        }

        .contentItem {
          .contentTitle {
            margin-top: 10px;
            height: 16px;
            font-size: 16px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            line-height: 16px;
          }

          .tips {
            margin-top: 23px;
            height: 18px;
            border-radius: 10px 10px 10px 10px;
            opacity: 1;
            border: 1px solid #b9bfc4;
            font-size: 10px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #777a82;
            line-height: 16px;
            padding: 0px 8px;
            display: inline-block;
          }

          .time {
            margin-top: 23px;
            width: 198px;
            height: 14px;
            font-size: 14px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            line-height: 14px;
          }
        }

        .label {
          position: absolute;
          top: 0;
          left: 0;
          width: 62px;
          height: 24px;
          // background: #45b757;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 8px 0 8px 0;
          color: #ffffff;
          font-size: 14px;
          text-align: center;
          line-height: 24px;
        }

        .labelExpired {
          position: absolute;
          top: 0;
          left: 0;
          width: 62px;
          height: 24px;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 8px 0 8px 0;
          color: #ffffff;
          font-size: 14px;
          text-align: center;
          line-height: 24px;
        }
      }
    }
  }

  .activation {
    width: 100%;

    .activationBox {
      margin-top: 24px;

      .colleges-form {
        width: 425px;
        margin: 0 auto;
      }

      /deep/ .el-input {
        width: 384px !important;
        height: 42px !important;
        background: #ffffff;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        border: 1px solid #dde0e7;
      }

      /deep/ .el-form-item__label {
        margin-right: 0;
        color: #666;
      }

      .activationBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 425px;
        height: 50px;
        background: linear-gradient(90deg, #0270e8 0%, #21b4f5 100%);
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        font-size: 16px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        padding: 0px !important;
        margin: 0 auto;
      }

      .inputItem {
        width: 480px;
      }

      .errorTips {
        color: #f33232;
        font-size: 14px;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
}

/deep/ .error-dialog {
  display: flex;
  align-items: center;
  justify-content: center;

  .el-dialog {
    width: 360px;
    /* height: 306px; */
    // background: #ffffff;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    box-shadow: none;
    width: 372px;
    height: 452px;
    background-image: url("~@/assets/img/partner/jihuo.png");
    background-size: 372px 452px;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;

    .el-dialog__header {
      display: none;
    }
  }
}

.dialogBox {
  padding: 0px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;

  .dialogImg {
    width: 60px;
    height: 60px;
    background: linear-gradient(138deg, #7ae8a6 0%, #52cd83 100%);
    opacity: 1;
    border-radius: 50%;
  }

  .dialogTips {
    // width: 129px;
    height: 46px;
    font-size: 35px;
    font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 46px;
    margin-top: 129px;
  }

  .className {
    margin: 0 auto;
    margin-top: 32px;
    height: 16px;
    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    white-space: pre-wrap;
    width: 80%;
    text-align: center;
    margin-bottom: 20px;
  }

  .time {
    margin-top: 16px;
    height: 16px;
    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    line-height: 16px;
  }

  .colleges-learn {
    width: 182px;
    height: 50px;
    background: linear-gradient(90deg, #0270e8 0%, #21b4f5 100%);
    border-radius: 25px 25px 25px 25px;
    opacity: 1;
    font-size: 16px;
    border: none;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    line-height: 50px;
    margin-top: 55px;
    text-align: center;
  }
}

/deep/ .el-input__inner {
  background: #FFFFFF !important;
}
</style>
